import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';

import { DOSSIERS_PAGE_URL, ORGANIZATION_PAGE_URL, UPLOAD_EASY_PREMIUM_DOSSIERS_PAGE_URL } from '../../routes';
import history from '../../utils/history';
import organizationHelper from '../../utils/organization-helper';
import storage from '../../utils/storage';

const TypedRoute = ({ component: Component, type, isMenuItem, ...remainingProps }) => {
    const { organization } = useSelector((state) => state.organization);
    const { account } = useSelector((state) => state.auth);

    useEffect(() => {
        if (['dev', 'development', 'test'].includes(process.env.NODE_ENV)) return;
        if (organization && account && !organization.isDemo) {
            mixpanel.people.set(
                {
                    $organization: organization.name,
                    $organizationId: organization._id,
                },
                () => mixpanel.track_pageview({ organization: organization.name, organizationId: organization._id, utilisateur: account.fullName })
            );
        }
    }, [organization, account]);

    useEffect(() => {
        if (!['/connexion'].includes(remainingProps.path) && organization && organization.accessOnlyToMPRPage) history.replace('/dossiers-financement');
    }, [organization, remainingProps.path]);

    useEffect(() => {
        if (remainingProps.title) document.title = `Renolib | ${remainingProps.title}`;
    }, [remainingProps.title]);

    const shouldRenderComponent =
        !storage.isAuthenticate() ||
        ['validation', '/dossier-financement/:uuid/:key', '/document-signe', '/signature', '/consentement-credit', '/consentement-credit/:financingConsentRequestId'].some((url) =>
            remainingProps.path.includes(url)
        );

    return (
        <Route
            {...remainingProps}
            render={(props) =>
                type === 'private' ? (
                    storage.isAuthenticate() ? (
                        shouldRedirectToDossiersPage(organization, remainingProps.path) ? (
                            <Redirect to={DOSSIERS_PAGE_URL} />
                        ) : (
                            <Component {...props} />
                        )
                    ) : (
                        <Redirect to='/connexion' />
                    )
                ) : shouldRenderComponent ? (
                    <Component {...props} />
                ) : (
                    <Redirect to='/' />
                )
            }
        />
    );
};

/**
 * @param {Organization} organization
 * @param {string} path
 */
function shouldRedirectToDossiersPage(organization, path) {
    return organizationHelper.isEasyPremiumOrganization(organization) && !path.includes(DOSSIERS_PAGE_URL) && path !== UPLOAD_EASY_PREMIUM_DOSSIERS_PAGE_URL && path !== ORGANIZATION_PAGE_URL;
}

TypedRoute.propTypes = {
    type: PropTypes.oneOf(['public', 'private', '']),
};

TypedRoute.defaultProps = {
    type: 'public',
};

export default TypedRoute;
