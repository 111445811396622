export default [
    {
        property: 'validityDurationOfQuotations',
        label: 'Date de validité des devis obligatoire',
        type: 'number',
        required: true,
    },
    {
        property: 'orderBy',
        label: "A l'ordre",
        type: 'string',
    },
    {
        property: 'iban',
        label: 'IBAN',
        type: 'string',
        dataType: 'iban',
    },
    {
        property: 'bic',
        label: 'IBAN',
        type: 'string',
        dataType: 'iban',
    },
    {
        property: 'advancePayment',
        label: 'Acompte',
        type: 'number',
        required: true,
    },
    {
        property: 'paymentDelay',
        label: 'Délai de paiement',
        type: 'number',
        required: true,
    },
    {
        property: 'latePenalties',
        label: 'Taux de pénalités de retard',
        type: 'number',
        required: true,
    },
    {
        property: 'hideLatePenalties',
        label: 'Masquer les pénalités de retard sur les devis et factures',
        type: 'boolean',
    },
    {
        property: 'recoveryFees',
        label: 'Frais de recouvrement',
        type: 'number',
        required: true,
    },
    {
        property: 'phone',
        label: 'Téléphone fixe',
        type: 'string',
        dataType: 'phoneNumber',
    },
    {
        property: 'email',
        label: 'Adresse email',
        type: 'string',
        dataType: 'emailAddress',
        required: true,
    },
];
