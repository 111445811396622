import { FULLY_UPDATE_ORGANIZATION_TOOLTIP_CONTENT } from '../utils/messages/organizations';

import accountHelper from './account-helper';
import { customerTypes, invitationStatuses, invitationStatusesTranslation, paymentTypesTranslations } from './enums';
import { ACCOUNT_NOT_DELETABLE, ACCOUNT_NOT_VERIFIED } from './messages';
import { extractPropertiesFromObject } from './object.helper';
import { isNonEmptyArray, isNonEmptyObject, isNullishOrEmpty, isValidValue } from './';

function isPaymentTypeEnabled(organizationBilling = {}, paymentType) {
    const { paymentTypes = [] } = organizationBilling;

    return paymentTypes.includes(paymentType);
}

function formatOrganizationBillingPaymentTypes(organizationBilling = {}) {
    const { paymentTypes = [] } = organizationBilling;

    return paymentTypes.map((paymentType) => paymentTypesTranslations[paymentType]).join(', ');
}

function getCurrentOrganization(organization = {}, remoteOrganizationInformation = {}) {
    return isNonEmptyObject(organization) ? organization : parseRemoteOrganizationInformation(remoteOrganizationInformation);
}

function parseRemoteOrganizationInformation(remoteOrganizationInformation) {
    return isNonEmptyObject(remoteOrganizationInformation)
        ? {
              ...remoteOrganizationInformation.details,
              organizationAddresses: remoteOrganizationInformation.addresses,
              organizationContacts: remoteOrganizationInformation.contacts,
          }
        : {};
}

function formatEditable(editable) {
    return isValidValue(editable) ? editable : false;
}

function formatOrganizationInvitations(organizationInvitations = []) {
    return organizationInvitations
        .filter((invitation) => {
            return !invitation.email.includes('support@renolib.fr');
        })
        .map((invitation) => ({
            firstName: extractInvitationFirstName(invitation),
            lastName: extractInvitationLastName(invitation),
            email: invitation.email,
            phone: extractInvitationPhone(invitation),
            mobile: extractInvitationMobile(invitation),
            status: invitation.status,
            statusDate: extractInvitationStatusDate(invitation),
            invitation,
        }));
}

function extractInvitationFirstName({ account }) {
    return account?.firstName;
}

function extractInvitationLastName({ account }) {
    return account?.lastName;
}

function extractInvitationPhone({ account }) {
    return account?.phone;
}

function extractInvitationMobile({ account }) {
    return account?.mobile;
}

function extractInvitationStatusDate({ status, sendingDate, acceptanceDate, confirmationDate }) {
    const statusDateValuesPerStatus = {
        [invitationStatuses.SENT]: sendingDate,
        [invitationStatuses.ACCEPTED]: acceptanceDate,
        [invitationStatuses.CONFIRMED]: confirmationDate,
    };

    return statusDateValuesPerStatus[status];
}

function formatInvitationStatus(status) {
    return {
        [invitationStatuses.SENT]: invitationStatusesTranslation.SENT,
        [invitationStatuses.ACCEPTED]: invitationStatusesTranslation.ACCEPTED,
        [invitationStatuses.CONFIRMED]: invitationStatusesTranslation.CONFIRMED,
    }[status];
}

function isFirstNameColumnSortable(organizationInvitations = []) {
    return organizationInvitations.every((invitation) => isValidValue(extractInvitationFirstName(invitation)));
}

function isLastNameColumnSortable(organizationInvitations = []) {
    return organizationInvitations.every((invitation) => isValidValue(extractInvitationLastName(invitation)));
}

function getOrganizationFullyUpdateTooltipMessage(organization) {
    return !isOrganizationFullyUpdatable(organization) ? FULLY_UPDATE_ORGANIZATION_TOOLTIP_CONTENT : '';
}

function isOrganizationFullyUpdatable({ fullyUpdatable }) {
    return fullyUpdatable;
}

function isInvitationButtonDisabled(account) {
    return !account.isVerified;
}

function getInvitationButtonTooltipContent(account) {
    return !account.isVerified ? ACCOUNT_NOT_VERIFIED : '';
}

function canDeleteAccount(account, organization, invitation) {
    if (isNullishOrEmpty(invitation?.account)) return isAccountDeletableByLoggedUser(account, organization);
    return isAccountDeletableByLoggedUser(account, organization);
}

function isAccountDeletableByLoggedUser(account, organization) {
    const mainAccount = organization.accounts[0];

    const role = accountHelper.extractAccountRole(account, organization);

    if (isNonEmptyObject(role) && role.name === 'ADMIN') return true;
    return account.id === mainAccount.id;
}

function getDeleteInvitationButtonMessage(account, organization, invitation) {
    return canDeleteAccount(account, organization, invitation) ? '' : ACCOUNT_NOT_DELETABLE;
}

function extractOrganizationDetails(organization) {
    return extractPropertiesFromObject(organization, ['siren', 'siret', 'name', 'capital', 'type', 'vat', 'ape', 'naf', 'logo', 'website', 'rcs']);
}

function isBudgetlyssFinancingAccessEnabled(organization, quotation) {
    return !!organization?.defaultPermissions?.budgetlyssFinancingAccess && quotation.customer?.customerType !== customerTypes.CUSTOMER_PM;
}

function toOrganizationSelectOption(organization) {
    return { label: formatOrganizationName(organization), value: organization.uuid };
}

function formatOrganizationName(organization) {
    const name = organization.name;
    if (isNullishOrEmpty(name)) return '';

    return isNullishOrEmpty(organization.alias) ? name : `${name} - ${organization.alias}`;
}

/**
 * @param {Organization} organization
 */
function areOrganizationAdministrativeDocumentsValid(organization) {
    if (isNullishOrEmpty(organization) || organization.isLegacy || isNullishOrEmpty(organization.documentsValidity)) return true;

    return organization.documentsValidity.administrativeDocumentsValidity.isValid;
}

/**
 * @param {Organization} organization
 */
function isOrganizationLegacy(organization) {
    return isNonEmptyObject(organization) ? organization.isLegacy : false;
}

/**
 * @param {Organization} organization
 * @param {string} applicationPage
 */
function doesOrganizationHaveExternalInformationsAccess(organization, applicationPage) {
    const { externalInformationsAccess = [] } = organization;

    return isNonEmptyArray(externalInformationsAccess) && isNonEmptyObject(externalInformationsAccess.find(({ applicationPages }) => applicationPages.includes(applicationPage)));
}

/**
 * @param {Organization} organization
 */
function isEasyPremiumOrganization(organization) {
    return isNonEmptyObject(organization) && organization.isEasyPremium;
}

export default {
    isPaymentTypeEnabled,
    formatOrganizationBillingPaymentTypes,
    getCurrentOrganization,
    formatEditable,
    formatOrganizationInvitations,
    formatInvitationStatus,
    isFirstNameColumnSortable,
    isLastNameColumnSortable,
    getOrganizationFullyUpdateTooltipMessage,
    isOrganizationFullyUpdatable,
    getInvitationButtonTooltipContent,
    isInvitationButtonDisabled,
    canDeleteAccount,
    getDeleteInvitationButtonMessage,
    extractOrganizationDetails,
    isBudgetlyssFinancingAccessEnabled,
    toOrganizationSelectOption,
    formatOrganizationName,
    areOrganizationAdministrativeDocumentsValid,
    isOrganizationLegacy,
    doesOrganizationHaveExternalInformationsAccess,
    isEasyPremiumOrganization,
};
