export const LABEL_SELECT_PRODUCT_USAGE = 'Usage du produit';
export const LABEL_SELECT_PRODUCT_CATEGORY = 'Catégorie de produit';
export const LABEL_SELECT_PRODUCT_SUB_CATEGORY = 'Sous-catégorie';
export const LABEL_SELECT_OPERATION = 'Opération';
export const LABEL_SELECT_VAT_RATE = 'TVA (%)';
export const LABEL_SELECT_REGULATOR_CLASS = 'Classe du régulateur telle que définie au paragraphe 6.1 de la communication de la commission 2014/C 207/02 dans le cadre du règlement (UE) n°813/2013.';
export const LABEL_SELECT_HEATING_PUMP_FUNCTIONING_TYPE = 'Type de fonctionnement de la pompe';

export const LABEL_INPUT_NAME = 'Nom';
export const LABEL_INPUT_BRAND = 'Marque';
export const LABEL_INPUT_REFERENCE = 'Référence';
export const LABEL_INPUT_CERTIFICATION_ACERMI = 'Certification (ACERMI)';
export const LABEL_INPUT_CERTIFICATION = 'Certification';
export const LABEL_INPUT_THERMAL_RESISTANCE = 'Résistance thermique (m².K/W)';
export const LABEL_INPUT_UNIT_PRICE = 'Prix unitaire hors taxes(€)';
export const LABEL_INPUT_SOLAR_FACTOR = 'Facteur solaire Sw';
export const LABEL_INPUT_SURFACE_TRANSMISSION_COEFFICIENT = 'Coefficient de transmission surfacique Uw (W/m².K)';
export const LABEL_INPUT_REGULATOR_BRAND = 'Marque du régulateur';
export const LABEL_INPUT_REGULATOR_REFERENCE = 'Référence du régulateur';
export const LABEL_INPUT_NOMINAL_THERMAL_POWER = 'Puissance thermique nominale P en kW';
export const LABEL_INPUT_SEASONAL_ENERGY_EFFICIENCY = 'Efficacité énergétique saisonnière (ηs en %) selon le règlement (eu) n°813/2013 de la commission du 2 août 2013 (ETAS)';
export const LABEL_INPUT_PERFORMANCE_FACTOR = "Coefficient de performance (COP) de l'équipement conformément aux conditions de la norme EN 16147";

export const LABEL_SELECT_VENTILATION_MODEL = 'Modèle de ventilation';
export const LABEL_SELECT_VENTILATION_TYPE = 'Type de ventilation';
export const LABEL_SELECT_VENTILATION_CLASS = 'Classe énergétique du système de ventilation selon le règlement européen (UE) n°1254/2014';
export const LABEL_INPUT_TECHNICAL_NOTICE_REFERENCE = "Référence de l'avis technique";
export const LABEL_INPUT_TECHNICAL_NOTICE_EXPIRATION_DATE = 'Date de fin de validité de l’avis technique';
export const LABEL_INPUT_WEIGHTED_ELECTRICAL_POWER = 'Puissance électrique absorbée pondérée';
export const LABEL_SELECT_BOX_TYPE = 'Type de caisson';
export const LABEL_INPUT_BOX_BRAND = 'Marque du caisson';
export const LABEL_INPUT_BOX_REFERENCE = 'Référence du caisson';
export const LABEL_INPUT_EXTRACT_VALVE_BRAND = "Marque des bouches d'extraction";
export const LABEL_INPUT_EXTRACT_VALVE_REFERENCE = "Référence des des bouches d'extraction";

export const LABEL_SELECT_CONSTITUTION_TYPE = 'Type de constitution de la toiture';
export const LABEL_INPUT_FACTEUR_SOLAIRE = 'Facteur solaire Sw';

export const LABEL_BUTTON_ADD_PRODUCT = 'Ajouter un produit';
export const LABEL_BUTTON_ADD_OPERATION = 'Ajouter une opération';
export const LABEL_ADD_MAIN_PRODUCT = 'Ajouter un produit de rénovation énergétique';

export const MODAL_TITLE_ADD_PRODUCT = 'Ajouter un nouveau produit';
export const MODAL_TITLE_PROVIDE_PRODUCT = 'Renseigner un produit';

export const LABEL_NAME = 'Nom';
export const LABEL_BRAND = 'Marque';
export const LABEL_REFERENCE = 'Référence';
export const LABEL_DESCRIPTION = 'Description';
export const LABEL_SUB_CATEGORY = 'Sous-catégorie';
export const LABEL_CERTIFICATION = 'Certification';

export const LABEL_FORM_PRICE_PER_OPERATION = 'Prix par opération';

export const LABEL_SELECT_SHEET_METAL_COLOR = 'Couleur';
export const LABEL_INPUT_ABSORPTION_COEFFICIENT = "Coefficient d'absorption";
export const LABEL_INPUT_LAMBDA = 'Lambda';
export const LABEL_INPUT_THICKNESS = 'Épaisseur (mm)';

export const LABEL_LAYER_ONE = 'Couche Tôle acier';
export const LABEL_LAYER_TWO = "Couche Lame d'air";
export const LABEL_LAYER_THREE = 'Couche Isolant';
export const LABEL_LAYER_FOUR = 'Couche Isolant complémentaire';

export const LABEL_ABSORPTION_COEFFICIENT = "Coefficient d'absorption";
export const LABEL_LAMBDA = 'Lambda';
export const LABEL_THICKNESS = 'Épaisseur';
export const LABEL_THERMAL_RESISTANCE = 'Résistance thermique R';

export const LABEL_INSTALLATION_DESCRIPTION = 'Descriptif technique – Pose – Garantie';
export const LABEL_INPUT_INSTALLATION_DESCRIPTION = 'Descriptif technique – Pose – Garantie (optionnelle)';

export const LABEL_ADD_OTHER_PRODUCT = ' Ajouter un autre produit / service';
export const LABEL_OTHER_PRODUCT = 'Autre produit / service';

export const LABEL_INPUT_SEASONAL_PERFORMANCE_COEFFICIENT = 'Coefficient de performance saisonnier (SCOP) selon le règlement n°206/2012 de la commission du 6 mars 2012';
export const LABEL_INPUT_NOMINAL_POWER = 'Puissance nominale de la PAC (en kW) selon le règlement n°206/2012 de la commission du 6 mars 2012';

export const LABEL_SOLAR_FACTOR_CALCULATION_NOTE = 'Note de calcul du facteur solaire';

export const LABEL_INPUT_SOLAR_CAPTORS_PRODUCTIVITY = 'Productivité des capteurs solaires';
export const SOLAR_PRODUCT_CERTIFICATION_LABEL = 'Les capteurs solaires ont une certification CSTBat ou Solar Keymark ou équivalente';

export const LABEL_INPUT_HEATING_PUMP_COVERAGE_RATE = "Taux de couverture de la pompe à chaleur hors dispositif d'appoint indiqué dans la note de dimensionnement (en %)";
export const LABEL_INPUT_TANK_CAPACITY_IN_LITER = 'Capacité du ballon (en litres)';
export const LABEL_INPUT_CERTIFICATION_NUMBER_SOLARKEYMARK = 'Numéro de la certification SOLARKEYMARK';
export const LABEL_INPUT_TANK_CLASS = 'Classe du ballon';

export const LABEL_INPUT_PERFORMANCE_COEFFICIENT = "Le coefficient de performance (COP) de l'équipement conformément aux conditions de la norme EN 16147";
export const LABEL_INPUT_SEASONAL_COOLING_PERFORMANCE = 'Puissance (Seer) (Kw)';
export const LABEL_INPUT_NOMINAL_COOLING_CAPACITY = 'La puissance de refroidissement (Kw)';
export const LABEL_PAC_DOUBLE_SERVICE_WITH_ECS = 'Si PAC double service avec ECS';

export const MODAL_TITLE_ADD_OTHER_PRODUCT = 'Nouveau autre produit / service';
export const MODAL_TITLE_ADD_NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT = 'Nouveau type de travaux';

export const LABEL_ADDITIONAL_TANK_CAPACITY = 'Capacité du ballon tampon';

export const LABEL_INPUT_SHOWER_HEAD_CLASS = 'Classe ou label de la pomme de douche';
export const LABEL_INPUT_JET_REGULATOR_CLASS = "Type d'aérateurs";

export const LABEL_PIPE_NOMINAL_DIAMETER = 'Diamètre nominal (DN) de la canalisation (mm)';

export const THERMAL_COEFFICIENT_UD = 'Le coefficient thermique Ud (W/m². K)';
export const LABEL_EXTRA_DESCRIPTION = 'Description supplémentaire';

export const LABEL_EXTERNAL_UNIT_REFERENCE = "Référence de l'unité extérieure";
export const LABEL_INTERNAL_UNIT_REFERENCE = "Référence de l'unité intérieure";
