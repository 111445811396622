import axios from 'axios';

import dialogsActions from '../store/actions/ui/dialogs';
import formActions from '../store/actions/ui/form';
import { getTestsEnvVariables } from '../tests/utils/env-helper';
import { isCypressTesting, isNullish } from '../utils';
import history from '../utils/history';
import storage from '../utils/storage';

import { SIGN_IN_ROUTE } from './routes';

const { TEST_BACKEND_API_V1_ENDPOINT, TEST_BACKEND_API_V2_ENDPOINT } = getTestsEnvVariables();

const endpointV1 = axios.create({
    baseURL: isCypressTesting() ? TEST_BACKEND_API_V1_ENDPOINT : process.env.REACT_APP_BACKEND_API_ENDPOINT,
});
const endpointV2 = axios.create({
    baseURL: isCypressTesting() ? TEST_BACKEND_API_V2_ENDPOINT : process.env.REACT_APP_BACKEND_API_V2_ENDPOINT,
});

export const RESOURCE_NOT_FOUND_HTTP_STATUS = 404;
export const BAD_REQUEST_HTTP_STATUS = 400;
export const UNAUTHORIZED_HTTP_STATUS = 401;

export const GENERIC_ERROR = 'Error';

endpointV2.interceptors.request.use(
    function (request) {
        if (request.url.includes('null')) {
            throw new axios.Cancel(`Request <${request.url}> has been cancelled`);
        }
        if (isNullish(request.params)) {
            request.params = {};
        }
        request.params.organizationUUID = storage.getOrganizationUUID();

        return request;
    },
    function (error) {
        return Promise.reject(error);
    }
);

endpointV2.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        console.log(error.response, { UNAUTHORIZED_HTTP_STATUS, cond: error?.response?.status === UNAUTHORIZED_HTTP_STATUS });
        if (error?.response?.status === UNAUTHORIZED_HTTP_STATUS) {
            storage.clearAll();
            history.push(SIGN_IN_ROUTE);
        }

        return Promise.reject(error);
    }
);

function authHeaders(token) {
    const finalToken = token || storage.getToken();
    return finalToken ? { Authorization: `Bearer ${finalToken}` } : {};
}

function parseError(error) {
    let errorMessage = error.message;
    if (error.response && error.response.data) errorMessage = error.response.data.message;

    return typeof errorMessage !== 'string' ? 'Erreur serveur' : errorMessage;
}

function getAuthHeadersWithJSONContentType(token) {
    return {
        ...authHeaders(token),
        'Content-Type': 'application/json',
    };
}

function getAuthHeadersWithFormDataContentType() {
    return {
        ...authHeaders(),
        'Content-Type': 'application/x-www-form-urlencoded',
    };
}

function isBadRequestError(error) {
    return error.response && error.response.status === 400;
}

function isResourceNotFoundError(error) {
    return error.response && error.response.status === RESOURCE_NOT_FOUND_HTTP_STATUS;
}

function handleErrorResponse({ dispatch, errorType, showErrorDialog = true, callback }) {
    return (error) => {
        const errorMessage = parseError(error);
        if (errorType) dispatch({ type: errorType, payload: { errorMessage } });
        dispatch(formActions.stopRequesting());
        if (showErrorDialog) dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
        if (callback) callback({ errorMessage });
    };
}

function getOrganizationRouteUrl({ route }) {
    return `/organizations/${storage.getOrganizationUUID()}`.concat(route);
}

export {
    endpointV1,
    endpointV2,
    authHeaders,
    parseError,
    getAuthHeadersWithJSONContentType,
    getAuthHeadersWithFormDataContentType,
    isBadRequestError,
    handleErrorResponse,
    getOrganizationRouteUrl,
    isResourceNotFoundError,
};
