export const LABEL_EPAISSEUR = 'épaisseur (mm)';
export const LABEL_RESISTANCE = 'La résistance thermique R';
export const LABEL_CLASSE = 'Classe de l’isolant selon la norme NF EN 12 828+A1:2014';

export const LABEL_TYPE_FONCTIONNEMENT = 'type de fonctionnement';
export const LABEL_EFFICACITE_ENERGETIQUE = "L'efficacité énergétique saisonnière (η s) selon le règlement (EU) n°813/2013 de la commission du 2 août 2013 (ETAS)";
export const LABEL_CLASSE_REGULATEUR =
    'classe du régulateur (IV à VIII) telle que définie au paragraphe 6.1 de la communication de la Commission 2014/C 207/02 dans le cadre du règlement (UE) n°813/2013';
export const LABEL_REFERENCE_REGULATEUR = 'référence du régulateur';
export const LABEL_MARQUE_REGULATEUR = 'marque du régulateur';
export const LABEL_SEASONAL_PERFORMANCE_COEFFICIENT = 'Le coefficient de performance saisonnier (SCOP) selon le règlement n°206/2012 de la commission du 6 mars 2012';
export const LABEL_NOMINAL_POWER = 'La puissance nominale de la PAC (en kW) selon le règlement n°206/2012 de la commission du 6 mars 2012';

export const LABEL_FACTEUR_SOLAIRE = 'Le facteur solaire Sw';
export const LABEL_TYPE_DE_CONSTITUTION = 'type de constitution de la toiture';

export const LABEL_COEFFICIANT_DE_TRANSMISSION = 'Le coefficient de transmission surfacique Uw';

export const LABEL_PUISSANCE_THERMIQUE = 'La puissance thermique nominale P';

export const LABEL_CLASSE_ENERGETIQUE_VENTILATION = 'classe énergétique du système de ventilation selon le règlement européen (UE) n°1254/2014';
export const LABEL_TYPE_CAISSON = 'type de caisson';
export const LABEL_MARQUE_CAISSON = 'marque du caisson';
export const LABEL_REFERENCE_CAISSON = 'référence du caisson';
export const LABEL_MARQUE_BOUCHES_EXTRACTION = "marque des bouches d'extraction";
export const LABEL_REFERENCE_BOUCHES_EXTRACTION = "référence des bouches d'extraction";
export const LABEL_REFERENCE_AVIS_TECHNIQUE = "référence de l’Avis Technique (CCFAT ou autres organismes d'accréditation)";
export const LABEL_DATE_VALIDITE_AVIS_TECHNIQUE = "date de validité de l’Avis Technique (CCFAT ou autres organismes d'accréditation)";
export const LABEL_LA_POMPE_A_CHALEUR_EST_DE_TYPE_AIR_EAU =
    'La pompe à chaleur est de type air/eau comportant un dispositif d’appoint utilisant un combustible liquide ou gazeux et une régulation qui les pilote';

export const LABEL_MARQUE_BOUCHE_AIR = "marque des bouches d'entrées d'air";
export const LABEL_REFERENCE_BOUCHE_AIR = "référence des bouches d'entrées d'air";
export const LABEL_PUISSANCE_ELECTRIQUE = 'La puissance électrique absorbée pondérée';
export const LABEL_MODELE_VENTILATION = 'Modèle de ventilation';
export const LABEL_TYPE_VENTILATION = 'Type de ventilation mécanique contrôlée';

export const LABEL_COEFFICIANT_DE_PERFORMANCE = "Le coefficient de performance (COP) de l'équipement conformément aux conditions de la norme EN 16147";

export const HYBRID_SOLAR_CAPTORS = 'Les capteurs solaires sont des capteurs hybrides';
export const SOLAR_CERTIFICATION_LABEL = 'Certification CSTBat ou Solar Keymark ou équivalente des capteurs solaires (taille 10Mo maximum)';
export const LABEL_NATURE_FLUID_CAPTORS = 'Nature du fluide circulant dans les capteurs';
export const LABEL_ROOF_ONLY_CONSTITUTION_TYPE = 'Un système de toiture assurant à lui seul le facteur solaire requis';
export const LABEL_ROOF_ONLY_CONSTITUTION_TYPE_VALUE = 'La toiture est constituée d’un système de toiture assurant à lui seul le facteur solaire requis';
export const LABEL_SET_OF_ELEMENTS_CONSTITUTION_TYPE = 'Un ensemble d’éléments dont la composition permet d’obtenir le facteur solaire requis';
export const LABEL_SET_OF_ELEMENTS_CONSTITUTION_TYPE_VALUE = 'Mise en place d’éléments séparés dont la composition permet d’obtenir le facteur solaire requis';

export const SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER =
    'Le système est couplé à des émetteurs de chauffage central de type basse température permettant une optimisation de la valorisation de l’énergie solaire';

export const PRODUCT_UNDER_VERIFICATION = 'Ce produit est en cours de vérification';

export const LABEL_GREEN_FLAME_LABEL_SEVEN = 'Label flamme verte 7*';
export const LABEL_NOMINAL_YIELD = 'Rendement nominal';
export const LABEL_PARTICLE_EMISSION = 'Emissions de particules';
export const LABEL_CARBON_MONOXIDE_EMISSIONS = 'Emissions de monoxyde de carbone (CO)';
export const LABEL_NITROGEN_OXIDE_EMISSIONS = "Emissions d'oxyde d'azote (Nox)";
export const LABEL_SEASONAL_ENERGY_EFFICIENCY_ETAS = 'Efficacité énergétique saisonnière (Etas)';
export const LABEL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS = 'Emissions de composés organiques gazeux (COG)';

export const LABEL_SEASONAL_PARTICLE_EMISSIONS = 'Émissions saisonnières de particules';
export const LABEL_SEASONAL_NITROGEN_OXIDE_EMISSIONS = "Émissions saisonnières d'oxydes d'azote (Nox)";
export const LABEL_SEASONAL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS = 'Émissions saisonnières de composés organiques gazeux';
export const LABEL_SEASONAL_CARBON_MONOXIDE_EMISSIONS = 'Émissions saisonnières de monoxyde de carbone (CO)';
export const LABEL_RACKING_PROFILE = 'Profil de soutirage selon le règlement délégué (UE) n° 812/2013 de la Commission du 18 février 2013';
export const LABEL_USED_BIOMASS_IS_LIGNEOUS_BIOMASS_WITH_WOOD_BASE =
    'La biomasse utilisée est de la biomasse ligneuse à base de bûches de bois, de copeaux de bois, de bois comprimé sous forme de granulés, de bois comprimé sous forme de briquettes ou de sciure de bois';
export const LABEL_OLD_OR_NEW_SILO_OF_AT_LEAST_225_LITERS_PRESENCE = "Présence d'un silo d'au moins 225 litres, neuf ou existant";
export const LABEL_OLD_OR_NEW_BUFFER_TANK_PRESENCE = "Présence d'un ballon tampon, neuf ou existant";
export const LABEL_SEASONAL_ENERGY_EFFICIENCY_2015_1189 = 'Efficacité énergétique saisonnière selon le règlement (EU) n°2015/1189 de la commission du 28 avril 2015';
export const LABEL_FILL_IN_THE_INFORMATION_MANUALLY = 'Renseigner les informations manuellement';

export const LABEL_INPUT_EXCHANGER_THERMAL_EFFICIENCY = "Efficacité thermique de l'échangeur (%) selon la norme NF EN 13141-7";
export const LABEL_INPUT_EXCHANGER_STATIC_EFFICIENCY = "Efficacité de l'échangeur statique (%) selon les normes NF EN 308 ou NF EN 51-763";
export const LABEL_INPUT_EXCHANGER_STATIC_EFFICIENCY_CERTIFIED = "Efficacité de l'échangeur collectif (%) selon les normes NF EN 308 et certifié par un organisme Cofrac";
export const LABEL_INPUT_DOUBLE_FLOW_VENTILATION_BOX_BRAND = 'Marque du caisson de ventilation double flux';
export const LABEL_INPUT_DOUBLE_FLOW_VENTILATION_BOX_REFERENCE = 'Référence du caisson de ventilation double flux';
export const LABEL_INPUT_SELF_ADJUSTING_EXTRACTION_VENT_BRAND = "Marque des bouches d'extraction autoréglables ou hygroréglables";
export const LABEL_INPUT_SELF_ADJUSTING_EXTRACTION_VENT_REFERENCE = "Référence des bouches d'extraction autoréglables ou hygroréglables";

export const LABEL_SELECT_VENTILATION_ENERGY_CLASS_1254_2014 = 'Classe énergétique du système de ventilation selon le règlement européen (UE) n°1254/2014 de la Commission du 11 juillet 2014';
export const LABEL_RADIO_GROUP_DOUBLE_FLOW_BOX_IS_COLLECTIVE = 'Le caisson double flux est collectif';
export const LABEL_RADIO_GROUP_STATIC_EXCHANGER_IS_COLLECTIVE = "L'échangeur statique est collectif";
export const LABEL_TEMPERATURE_BASE_PONDEREE = 'Température extérieure';
export const LABEL_COEFFICIENT_OF_PERFORMANCE_7_45 = 'Coefficient de performance à 7°C ext / 45°C eau';
export const LABEL_COEFFICIENT_OF_PERFORMANCE = 'Coefficient de performance';
export const LABEL_COEFFICIENT_OF_PERFORMANCE_ECS = 'Coefficient de performance ECS à 7°C ext';
export const LABEL_COEFFICIENT_OF_PERFORMANCE_7_35 = 'Coefficient de performance à 7°C ext / 35°C eau';
export const LABEL_COEFFICIENT_OF_PERFORMANCE_7_55 = 'Coefficient de performance à 7°C ext / 55°C eau';
export const LABEL_PAC_DOUBLE_SRVICE_WITH_ECS = 'Pompe à chaleur double service avec ECS';
//export const LABEL_NOMINAL_POWER_WITH_TEMP = 'Puissance nominale kW à -7°C ext / 55°C eau (ou -7°C ext / 35°C eau)';
export const LABEL_NOMINAL_POWER_KW = 'Puissance nominale kW';
export const COVERED_SURFACE = 'Surface couverte';

export const LABEL_SELECT_BACKUP_ENERGY = "Energie de l'appoint";
export const LABEL_SELECT_RACKING_PROFILE = 'Profil de soutirage';
export const LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_ONE = 'La certification porte sur la globalité du système pour les appareils auto-stockeurs et à thermosiphon';
export const LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_TWO = 'La certification porte sur les capteurs solaires thermiques pour les appareils à circulation forcée';
export const LABEL_DOMTOM_WATER_HEATER_CERTIFICATION = 'Certification QB ou équivalente selon la norme NF EN ISO/CEI 17065';

export const LABEL_LUMINARY_MODELE = 'Modèle';
export const LABEL_LUMINARY_TYPE = 'Type';

export const LABEL_LIGHT_IS_LED = 'Ampoule de type LED';
export const LABEL_OPTICAL_ASSEMBLY = 'Ensemble optique fermé d’un degré de protection (IP)';
export const LABEL_LUMEN = 'Lumen';
export const LABEL_POWER_OF_THE_SYSTEM_W = 'Puissance (W) (puissance totale du système)';
export const LABEL_LIGHT_EFFICIENCY = 'Efficacité lumineuse (%) = Lumen / puissance W';
export const LABEL_INDEX_COLOR_RENDERING = 'Index Rendu couleur (IRC)';
export const LABEL_ULOR = 'ULOR (%)';
export const LABEL_ULR = 'ULR (%)';
export const LABEL_REPLACE_LIGHT_SOURCE = 'La source lumineuse peut être remplacée';

export const LABEL_PANEL_SUB_CATEGORY = 'Type de';
export const NAME_PANEL_SUB_CATEGORY = 'panelSubCategory';
export const LABEL_PANEL_POWER = 'Puissance du panneau (Wc)';
export const NAME_PANEL_POWER = 'panelPower';
export const LABEL_PANEL_CELLS = 'Cellule en watt';
export const NAME_PANEL_CELLS = 'panelCell';
export const LABEL_PANEL_DIMENSION = 'Dimensions H x L x P (mm)';
export const NAME_PANEL_DIMENSION = 'panelDimension';
export const LABEL_PANEL_WEIGHT = 'Poids (kg)';
export const NAME_PANEL_WEIGHT = 'panelWeight';
export const LABEL_PANEL_CERTIFICATIONS = 'Certifications';
export const NAME_PANEL_CERTIFICATIONS = 'panelCertifications';
export const LABEL_MANUFACTURER_WARRANTY = 'Garantie fabricant (année)';
export const NAME_MANUFACTURER_WARRANTY = 'manufacturerWarranty';
export const LABEL_LINEAR_WARRANTY_85PCT = 'Garantie linéaire (année)';
export const NAME_LINEAR_WARRANTY_85PCT = 'linearWarranty85pct';
export const LABEL_MAX_DC_VOLTAGE = "Tension d'entrée DC maximum";
export const NAME_MAX_DC_VOLTAGE = 'maxDCVoltage';
export const LABEL_YIELD = 'Rendement (%)';
export const NAME_YIELD = 'yield';
export const LABEL_MATERIAL_WARRANTY = 'Garantie matériel (année)';
export const NAME_MATERIAL_WARRANTY = 'materialWarranty';
export const LABEL_CERTIFICATE_OF_COMPLIANCE = 'Certificat de conformité';
export const NAME_CERTIFICATE_OF_COMPLIANCE = 'compliancyCertificate';
export const LABEL_MODEL = 'Modèle';
export const NAME_MODEL = 'model';
export const LABEL_ECN_CERTIFICATE = 'Certification ECN';
export const NAME_ECN_CERTIFICATE = 'ecnCertificate';
export const LABEL_DECENNAL_INSURANCE = 'Assurance décennale';
export const NAME_DECENNAL_INSURANCE = 'decennalInsurance';

export const LABEL_HEATING_PUMP_USAGE = 'Usage de la pompe à chaleur';
export const LABEL_REGULATOR_CLASS = 'Classe du régulateur (IV à VIII) telle que définie au paragraphe 6.1 de la communication de la commission 2014/C 207/02';

export const LABEL_PIPE_NOMIMAL_DIAMETER = 'Diamètre nominal (DN) de la canalisation (mm)';

export const LABEL_INSULATING_COVER_IS_FLEXIBLE = "La housse est souple, démontable et équipée d'un système de fermeture";
export const LABEL_INSULATING_COVER_IS_MADE_OF_MINERAL_WOOL_BASED_INSULATOR = "La house est constituée d'un isolant à base de laine minérale";
export const LABEL_INSULATING_COVER_IS_NOT_AN_INSULATING_SLEEVE = "La housse n'est pas un manchon isolant";
export const LABEL_HEAT_TRANSFER_FLUID_TEMPERATURE = 'Température du fluide caloporteur (°C)';
export const LABEL_MAXIMUM_SERVICE_TEMPERATURE = 'Température maximale de service (°C)';
export const LABEL_FIFTY_DEGREES_THERMAL_RESISTANCE = "Résistance thermique de l'isolant R (m².K/W) à une température moyenne de 50°C";
export const LABEL_ONE_HUNDRED_DEGREES_THERMAL_RESISTANCE = "Résistance thermique de l'isolant R (m².K/W) à une température moyenne de 100°C";
export const LABEL_CAPTOR_BRAND = 'Marque du capteur solaire';
export const LABEL_CAPTOR_REFERENCE = 'Référence du capteur solaire';

export const LABEL_ISOLANT_REFLECTIVE = 'Votre isolant est';
export const LABEL_REGULATOR_BRAND = 'Marque du régulateur';
export const LABEL_REGULATOR_REFERENCE = 'Référence du régulateur';
export const LABEL_REGULATOR_TECHNICAL_FILE = 'Fiche technique du régulateur';

export const LABEL_BOILER_STORAGE_CAPACITY = 'Capacité de stockage du ballon';
export const LABEL_BOILER_ENERGY_EFFICIENCY_CLASS = "Classe d'efficacité énergétique ballon";
export const LABEL_BOILER_CAPTORS_SYSTEM_TYPE_EXCLUSION = 'Le système est de type thermosiphon ou auto-stockeur';
export const LABEL_BOILER_CAPTORS_HYBRID_EXCLUSION = 'Les capteurs solaires sont des capteurs hybrides';
export const LABEL_BOILER_ENERGY_EFFICIENCY = "Efficacité énergétique pour le chauffage de l'eau selon le règlement (UE) n° 814/2013";

export const LABEL_TYPE_SYSTEME = 'Type de système';

export const LABEL_DOUBLE_FLOW_VENTILATION_CERTIFICATION = 'Produit certifié NF 205';

export const LABEL_FORM_FILE_NAME = 'file';
