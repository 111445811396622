export default [
    {
        property: 'dossiers',
        label: 'Fichier des dossiers',
        type: 'file',
        required: true,
    },
    {
        property: 'operations',
        label: 'Fichier des opérations',
        type: 'file',
        required: true,
    },
];
